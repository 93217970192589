import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import PropTypes from "prop-types";
import talkToDoctorIcons from "ps-assets/img/talk-to-doctor/talk-to-doctor.png";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const talkToDoctor = ({ classes }) => {
  return (
    <Layout small={true} title={``} summary={``}>
      <Helmet key="helmetTags">
    <title>Talk To Doctor | StarHealth.in</title>
  </Helmet>
  {process.env.ENABLE_HELMET == `true` && (
    <Helmet key="helmetTags">
      <link
        rel="canonical"
        href={url + `talk-to-doctor`}
      />
      <meta name="title" content="Talk To Doctor | StarHealth.in" />
      <meta name="twitter:title" content="Talk To Doctor | StarHealth.in" />
      <meta
        name="description"
        content="Star Health Talk To Doctor"
      />
      <meta
        name="keywords"
        content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online,Star Health Locate us, Health Insurance Specialist In India."
      />
      <meta property="og:title" content="Talk To Doctor | StarHealth.in" />
      <meta property="og:description" content="Star Health Talk To Doctor" />
      <meta property="og:url" content={url + `talk-to-doctor`}/>
      <meta name="Talk To Doctor | StarHealth.in" />
      <meta name="twitter:description" content="Star Health Talk To Doctor" />
      <meta property="twitter:url" content={url + `talk-to-doctor`} />
    </Helmet>
  )}
      <div
        className={classNames(classes.main, classes.mainRaised)}
        style={{ marginBottom: 100 }}
      >
        <div className={classes.section}>
          <div className={classes.container} style={{ textAlign: `center` }}>
            <div className={classes.imagesContainer}>
            <h1 className={classes.h1title} style={{textAlign:'center',marginTop:'-3%',marginBottom:'2%'}}>Talk To Doctor</h1>
              <div>
                <a
                  href="https://play.google.com/store/apps/details?id=com.starhealthinsurance.talktostar"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ position: `absolute` }}
                  className={classes.iconTop + ` ` + classes.googlePlay}
                ></a>
                <a
                  href="https://apps.apple.com/us/app/talk-to-star/id1501668829?ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ position: `absolute` }}
                  className={classes.iconTop + ` ` + classes.appStore}
                ></a>
                <img className={classes.talkToDoctor} src={talkToDoctorIcons} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
talkToDoctor.propTypes = {
  classes: PropTypes.object
};

export default withStyles(customStaticPageStyle)(talkToDoctor);
